import React from "react";
import TimePicker from "react-time-picker";
import SwitchArrowIcon from "components/icons/SwitchArrowIcon";
import "./EventSessionTimePicker.scss";

const EventSessionTimePicker = ({ time, setTime }) => {
	const addHour = () => {
		const maxHours = 23;
		let hours = time ? Number(time.split(":")[0]) : 0;
		const minutes = time ? time.split(":")[1] : "00";
		const addedHours = String(hours < maxHours ? ++hours : 0);
		const newHours = addedHours.length > 1 ? addedHours : "0" + addedHours;

		setTime(`${newHours}:${minutes}`);
	};

	const deductHour = () => {
		const minHours = 0;
		let hours = time ? Number(time.split(":")[0]) : 0;
		const minutes = time ? time.split(":")[1] : "00";
		const deductedHours = String(hours > minHours ? --hours : 23);
		const newHours =
			deductedHours.length > 1 ? deductedHours : "0" + deductedHours;

		setTime(`${newHours}:${minutes}`);
	};

	const addMinute = () => {
		const maxMinutes = 59;
		const hours = time ? time.split(":")[0] : "00";
		let minutes = time ? Number(time.split(":")[1]) : 0;
		const addedMinutes = String(minutes < maxMinutes ? ++minutes : 0);
		const newMinutes =
			addedMinutes.length > 1 ? addedMinutes : "0" + addedMinutes;

		setTime(`${hours}:${newMinutes}`);
	};

	const deductMinute = () => {
		const minMinutes = 0;
		const hours = time ? time.split(":")[0] : "00";
		let minutes = time ? Number(time.split(":")[1]) : 0;
		const deductedMinutes = String(minutes > minMinutes ? --minutes : 59);
		const newMinutes =
			deductedMinutes.length > 1 ? deductedMinutes : "0" + deductedMinutes;

		setTime(`${hours}:${newMinutes}`);
	};

	const showTime = () => {
		if (!time) return null;

		const [hours, minutes] = time.split(":");
		const formattedHours = hours.padStart(2, "0");
		const formattedMinutes = minutes.padStart(2, "0");

		return `${formattedHours}:${formattedMinutes}`;
	};

	const setUpTime = (newTime) => {
		if (!newTime) return;

		const [hours, minutes] = newTime.split(":");
		const newHours = parseInt(hours, 10);

		if (isNaN(newHours) || isNaN(parseInt(minutes, 10))) {
			return;
		}

		if (
			newHours < 0 ||
			newHours > 23 ||
			parseInt(minutes, 10) < 0 ||
			parseInt(minutes, 10) > 59
		) {
			return;
		}

		const formattedHours = newHours.toString().padStart(2, "0");
		const formattedMinutes = minutes.padStart(2, "0");

		setTime(`${formattedHours}:${formattedMinutes}`);
	};

	return (
		<>
			<div className="time-picker">
				<div className="time-picker__time">
					<div className="time-picker__actions">
						<SwitchArrowIcon onClick={addHour} className="time-picker__btn" />
						<SwitchArrowIcon
							onClick={addMinute}
							className="time-picker__action-icon time-picker__btn"
						/>
					</div>
					<TimePicker
						onChange={setUpTime}
						value={showTime()}
						disableClock={true}
						clearIcon={null}
						format={"h:m a"}
					/>
					<div className="time-picker__actions">
						<SwitchArrowIcon
							className="time-picker__btn time-picker__btn--down"
							onClick={deductHour}
						/>
						<SwitchArrowIcon
							onClick={deductMinute}
							className="time-picker__action-icon time-picker__btn time-picker__btn--down"
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default EventSessionTimePicker;
