import axios from "axios";
import { useSelector } from "react-redux";
const { useState, useEffect, useCallback } = require("react");

/**
 * @typedef {Object} Options
 * @property {string} url
 * @property {"GET"|"POST"|"PUT"|"DELETE"} [method]
 * @property {Object} [headers]
 * @property {boolean} [immediate=true]
 */

/**
 * @template T
 * @param {Options} options
 * @returns {{ data: T | null, isLoading: boolean, error: string | null, refetch: () => void }}
 */

function useFetch(options) {
	const { authToken } = useSelector((state) => state.auth);
	const {
		url,
		headers = { Authorization: `Bearer ${authToken}` },
		method = "GET",
		immediate = true,
	} = options;

	const [data, setData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	const fetchData = useCallback(
		async (payload) => {
			setIsLoading(true);

			const axiosMethods = {
				GET: () => axios.get(url, { headers }),
				POST: () => axios.post(url, payload, { headers }),
				PUT: () => axios.put(url, payload, { headers }),
				DELETE: () => axios.delete(url, { headers }),
			};

			try {
				const response = await axiosMethods[method]();
				setData(response.data);
			} catch (error) {
				const errorMessage = error.response
					? error.response.data.message
					: error.message;

				setError(errorMessage);
			} finally {
				setIsLoading(false);
			}
		},
		[url, method, headers]
	);

	useEffect(() => {
		if (immediate) {
			fetchData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [immediate]);

	function refetch() {
		fetchData();
	}

	return { data, isLoading, error, refetch, fetchData };
}

export default useFetch;
