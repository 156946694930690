import React, { useEffect, useState } from "react";
import useFetch from "../../../../../../hooks/useFetch";
import Spinner from "../../../../../Spinner/Spinner";
import CreateNote from "./CreateNote";
import ViewNote from "./ViewNote";
import EditNote from "./EditNote";
import { PROGRAMMES_URLS } from "../../../../../../constants/api";

function Note({ activityId }) {
	const [note, setNote] = useState(undefined);
	const [createNoteValue, setCreateNoteValue] = useState("");
	const [edit, setEdit] = useState(false);

	const {
		data,
		isLoading,
		fetchData: getNote,
	} = useFetch({
		url: PROGRAMMES_URLS.NOTES.PARTICIPANT.GET(activityId),
		immediate: false,
	});

	const {
		fetchData: getNotes,
		isLoading: notesIsLoading,
		data: notes,
	} = useFetch({
		url: PROGRAMMES_URLS.NOTES.PARTICIPANT.LIST.GET,
		immediate: false,
	});

	const { isLoading: createNoteIsLoading, fetchData: createNote } = useFetch({
		url: PROGRAMMES_URLS.NOTES.POST,
		immediate: false,
		method: "POST",
	});

	const { isLoading: updateNoteIsLoading, fetchData: updateNote } = useFetch({
		url: PROGRAMMES_URLS.NOTES.PUT(note?.id),
		immediate: false,
		method: "PUT",
	});

	const { isLoading: deleteNoteIsLoading, fetchData: deleteNote } = useFetch({
		url: PROGRAMMES_URLS.NOTES.DELETE(note?.id),
		immediate: false,
		method: "DELETE",
	});

	const textEditorOptions = [
		["bold", "italic", "underline"],
		[{ list: "ordered" }, { list: "bullet" }],
	];

	useEffect(() => {
		if (data) {
			setNote(data);
		}
	}, [data]);

	useEffect(() => {
		getNotes();
	}, [activityId]);

	useEffect(() => {
		if (notes?.length) {
			const foundNote = notes.find(
				(note) => note.participant_activity_id === activityId
			);

			if (foundNote) {
				setNote(foundNote);
			} else {
				setNote(undefined);
			}
		}
	}, [notes, activityId]);

	const handleCreateNote = async () => {
		await createNote({
			content: createNoteValue,
			participant_activity_id: activityId,
		});
		await getNote();
		setCreateNoteValue("");
	};

	const handleSaveEdit = async () => {
		await updateNote({ content: note?.content });
		getNote();
		setEdit(false);
	};

	const handleCancelEdit = () => {
		setEdit(false);
		setNote(note);
	};

	const handleEditNoteChange = (content) => {
		setNote((prevNote) =>
			prevNote.id === note.id ? { ...prevNote, content } : prevNote
		);
	};

	const handleDeleteNote = async () => {
		await deleteNote();
		setNote(undefined);
		setCreateNoteValue("");
	};

	if (isLoading || notesIsLoading) {
		return <Spinner className="h-full" />;
	}

	return (
		<div className="mt-6 space-y-6">
			{!note && (
				<CreateNote
					textEditorOptions={textEditorOptions}
					createNoteValue={createNoteValue}
					setCreateNoteValue={setCreateNoteValue}
					isLoading={createNoteIsLoading}
					handleCreateClick={handleCreateNote}
					activityId={activityId}
				/>
			)}

			{!edit && note && (
				<ViewNote
					note={note}
					onEditClick={() => {
						setEdit(true);
					}}
					isLoading={deleteNoteIsLoading}
					onDeleteClick={handleDeleteNote}
				/>
			)}

			{edit && note && (
				<EditNote
					textEditorOptions={textEditorOptions}
					note={note}
					setEditNoteValue={handleEditNoteChange}
					isLoading={updateNoteIsLoading}
					handleSaveClick={handleSaveEdit}
					handleCancelClick={handleCancelEdit}
				/>
			)}
		</div>
	);
}

export default Note;
