import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useFetch from "../../../../../../hooks/useFetch";
import { PROGRAMMES_URLS } from "../../../../../../constants/api";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Spinner } from "react-spinners-css";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import axios from "axios";
import { getUserSingleProgram } from "../../../../../../redux/actions";
import { TrashIcon } from "../../../../../icons/TrashIcon";

function Notes() {
	const { programID } = useParams();
	const history = useHistory();
	const { singleProgram, singleProgramLoading } = useSelector(
		(state) => state.userPrograms
	);
	const dispatch = useDispatch();
	const { authToken } = useSelector((state) => state.auth);
	const [deletingNoteId, setDeletingNoteId] = useState(null);
	const [combinedNotes, setCombinedNotes] = useState([]);

	function handleBackClick() {
		history.goBack();
	}

	const {
		data: notes,
		isLoading,
		error,
		fetchData,
	} = useFetch({
		url: PROGRAMMES_URLS.NOTES.PARTICIPANT.GET_ALL(programID),
	});

	function deleteNote(id) {
		return axios.delete(PROGRAMMES_URLS.NOTES.DELETE(id), {
			headers: { Authorization: `Bearer ${authToken}` },
		});
	}

	const combineNotesWithTopics = useCallback(() => {
		const topics = singleProgram?.topics?.flat() || [];

		const combined = notes?.map((note) => {
			const topic = topics.find(
				(topic) => topic.id === note.participant_activity_id
			);
			return {
				...note,
				topicName: topic ? topic.name : "",
			};
		});

		setCombinedNotes(combined);
	}, [singleProgram, notes]);

	useEffect(() => {
		if (!Object.keys(singleProgram).length) {
			dispatch(getUserSingleProgram(programID));
		}
	}, [dispatch, programID, singleProgram]);

	useEffect(() => {
		combineNotesWithTopics();
	}, [combineNotesWithTopics]);

	const handleDeleteNote = async (id) => {
		setDeletingNoteId(id);
		try {
			await deleteNote(id);
			setCombinedNotes((prevNotes) =>
				prevNotes.filter((note) => note.id !== id)
			);
		} catch (error) {
			console.error("Failed to delete note:", error);
		} finally {
			setDeletingNoteId(id);
			fetchData();
		}
	};

	if (isLoading || singleProgramLoading) {
		return (
			<div className="flex items-center justify-center h-full">
				<Spinner />
			</div>
		);
	}

	if (error) {
		return (
			<div className="flex items-center justify-center flex-col gap-6 h-full">
				<h2 className="text-2xl">{error}</h2>
				<button
					className="w-max button border border-blue-600  hover:bg-blue-300/40 py-[11px] px-[10px] text-blue-600"
					onClick={handleBackClick}
				>
					Back to participants list
				</button>
			</div>
		);
	}

	return (
		<div className="p-8 space-y-6">
			<button
				className="w-max button border border-blue-600  hover:bg-blue-300/40 py-[11px] px-[10px] text-blue-600"
				onClick={handleBackClick}
			>
				Back to participants list
			</button>
			<h2 className="text-2xl font-medium">All Notes</h2>
			<div className="flex flex-col items-center gap-6">
				{combinedNotes?.map((note) => (
					<div
						key={note.id}
						className="flex items-start justify-between gap-6 bg-white w-full h-full p-4 rounded-lg shadow-md"
					>
						<div className="space-y-4">
							<h2 className="text-2xl">{note.topicName}</h2>
							<p
								className="text-pretty"
								dangerouslySetInnerHTML={{ __html: note.content }}
							/>
						</div>
						<div
							className="flex shrink-0 items-center justify-center cursor-pointer"
							onClick={() => handleDeleteNote(note.id)}
						>
							{deletingNoteId === note.id ? (
								<ClipLoader color="red" size={24} />
							) : (
								<TrashIcon className="text-red-500  size-8" />
							)}
						</div>
					</div>
				))}
			</div>
		</div>
	);
}

export default Notes;
